/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import RehypeReact from 'rehype-react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { jsx, css } from '@emotion/core';
import SEO from '../components/seo';
import ImageRow from '../components/image-row';
import PageContainer from '../components/page-container';
import Title from '../components/title';

const subStyle = css`
  ${tw`uppercase font-light text-2xl text-red mt-0 mb-6`};
`;

const Subtitle = props => <h2 css={subStyle}>{props.children}</h2>;

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    h2: Subtitle,
  },
}).Compiler;

const Template = ({ data }) => {
  const { markdownRemark: page } = data;
  const { edges: images } = data.images;

  return (
    <PageContainer>
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.metaDescription}
      />
      <div className="section">
        <Title text={page.frontmatter.title} />
        <div className="content" />
        <ImageRow images={images} />
        {renderAst(page.htmlAst)}
      </div>
    </PageContainer>
  );
};

export default Template;

export const pageQuery = graphql`
  query LocalWatersByPath($path: String!, $imgPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
        metaDescription
      }
    }
    images: allFile(limit: 3, filter: { relativePath: { regex: $imgPath } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
